import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LicenceExpiredComponent } from './licence-expired.component';

const routes: Routes = [
  { path: 'licence-expired', component: LicenceExpiredComponent, data: { title: 'LicenceExpired' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LicenceExpiredRoutingModule {}
