import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { LicenceService } from '@lib/shared/services/licence.service';
import { User } from '@lib/shared/models/user.model';
import { AlertService } from '@lib/shared/services/alert.service';
import { ToastService } from '@lib/shared/services/toast.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { UserService } from '@lib/shared/services/user.service';
import { AddUserComponent } from '@lib/admin/licences/add-user/add-user.component';
import { AddLicenceHolderComponent } from '@lib/admin/licences/add-licence-holder/add-licence-holder.component';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-licence-users',
  templateUrl: './licence-users.component.html',
  styleUrls: ['./licence-users.component.scss'],
})
@Sentry.TraceClassDecorator()
export class LicenceUsersComponent implements OnInit {
  public form: UntypedFormGroup;
  public licenceUsers: User[];
  private tokenSub: Subscription = new Subscription();
  public loadingUsers: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private licenceService: LicenceService,
    public credentialsService: CredentialsService,
    public userService: UserService,
    public alertController: AlertController,
    public modalController: ModalController,
    private fns: AngularFireFunctions,
    private alertService: AlertService,
    private toastService: ToastService
  ) {}

  async addUser(type: 'licenceHolder' | 'user') {
    try {
      const modal = await this.modalController.create({
        component: type == 'licenceHolder' ? AddLicenceHolderComponent : AddUserComponent,
        componentProps: {
          licenceId: this.route.snapshot.params['licenceId'],
        },
      });

      await modal.present();
      await modal.onDidDismiss();
      await this.getUserList();
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast(`Unable to add user. Please try again later.`);
    }
  }

  @Sentry.TraceMethodDecorator()
  async ngOnInit() {
    await this.getUserList();
    this.loadingUsers = false;
  }

  private async getUserList() {
    try {
      const licenceUsers = (
        await firstValueFrom(this.licenceService.getLicence(this.route.snapshot.params['licenceId']))
      ).users;
      this.licenceUsers = await Promise.all(
        licenceUsers.map(async (user) => await firstValueFrom(this.userService.getUserFromRef(user)))
      );
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast("Something's gone wrong. Please try again later.");
    }
  }

  public async changeUserPerms(user: User, newUserRole: 'licenceHolder' | 'user') {
    const userText = newUserRole === 'licenceHolder' ? 'licence holder' : 'user';
    const sure = await this.alertService.areYouSureAlert(`Are you sure you want to make ${user.name} a ${userText}?`);
    if (sure) {
      this.loadingUsers = true;
      this.tokenSub = this.credentialsService.token.subscribe({
        next: (token: string) => {
          this.tokenSub.unsubscribe();
          const data = { uid: user.id, token, newUserRole };
          const changeUserFn = this.fns.httpsCallable('changeUserPerms');
          changeUserFn(data).subscribe({
            next: async () => {
              await this.getUserList();
              this.loadingUsers = false;
              this.toastService.showAutoDissmissableErrorToast(
                `User changed to ${userText}`,
                'checkbox-outline',
                'success'
              );
            },
            error: (error) => {
              console.error(error);
              this.loadingUsers = false;
              this.toastService.showDissmissableErrorToast(
                `Unable to change user to ${userText}. Please try again later.`
              );
            },
          });
        },
        error: (error) => {
          console.error(error);
          this.toastService.showDissmissableErrorToast('Could not get your auth token. Please try again.');
        },
      });
    }
  }

  async confirmDelete(deleteUserName: string, deleteUserId: string) {
    const sure = await this.alertService.areYouSureAlert(
      'Are you sure you want to remove ' + deleteUserName + ' from this licence?'
    );
    if (sure) {
      this.loadingUsers = true;
      this.tokenSub = this.credentialsService.token.subscribe({
        next: (token: string) => {
          this.tokenSub.unsubscribe();
          const data = { uid: deleteUserId, token };
          const deleteUserFn = this.fns.httpsCallable('deleteUserV2');
          deleteUserFn(data).subscribe({
            next: async () => {
              this.toastService.showAutoDissmissableErrorToast('User removed', 'checkbox-outline');
              await this.getUserList();
              this.loadingUsers = false;
            },
            error: (error) => {
              console.error(error);
              this.toastService.showDissmissableErrorToast("Something's gone wrong. Please try again later.");
              this.loadingUsers = false;
            },
          });
        },
        error: (error) => {
          console.error(error);
          this.toastService.showDissmissableErrorToast('Could not get your auth token. Please try again.');
        },
      });
    }
  }
}
