<app-header></app-header>
<ion-content [formGroup]="form" (click)="onSubmit()">
  <h2>Settings</h2>
  <ion-item>
    <ion-label>Allow all users to edit curriculum</ion-label>
    <ion-toggle formControlName="allowUsersEditCurriculum" (click)="onSubmit()"></ion-toggle>
  </ion-item>

  <app-licence-users></app-licence-users>
</ion-content>
