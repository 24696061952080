<ion-content>
  <div>
    <div class="profile ion-padding">
      <div class="ion-text-center">
        <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
      </div>
      <h2 class="ion-text-center">{{ credentialsService.userData?.name }}</h2>
    </div>
  </div>

  <ion-card>
    <ion-card-content>
      <p><strong>Name:</strong> {{ credentialsService.userData?.name }}</p>
      <p><strong>Email/Username:</strong> {{ credentialsService.userData?.email }}</p>
      <p *ngIf="credentialsService?.userLicence && credentialsService?.userLicence?.expiresAt">
        <strong>License Expires:</strong>
        {{ credentialsService.userLicence.expiresAt?.toDate() | date: 'dd/MM/yyyy' }}
      </p>
      <p *ngIf="credentialsService.userLicence?.trial">
        <strong>You are a trial user</strong>
      </p>
    </ion-card-content>
  </ion-card>

  <ion-item routerLink="admin" *ngIf="credentialsService.userRole?.isAdmin">
    <ion-icon name="key" slot="start"></ion-icon>
    <ion-label translate>Admin</ion-label>
  </ion-item>

  <ion-item
    [routerLink]="['licence', credentialsService.userLicence?.id]"
    *ngIf="credentialsService.userRole?.permissions.editLicenceSettings"
  >
    <ion-icon name="settings-outline" slot="start"></ion-icon>
    <ion-label translate>Licence Settings</ion-label>
  </ion-item>

  <ion-item [routerLink]="['c/support']">
    <ion-icon name="help-outline" slot="start"></ion-icon>
    <ion-label translate>Support</ion-label>
  </ion-item>

  <ion-item (click)="deleteUser()">
    <ion-icon color="danger" name="trash-outline" slot="start"></ion-icon>
    <ion-label color="danger" translate>Delete my account</ion-label>
  </ion-item>

  <ion-item button (click)="logout()">
    <ion-icon name="exit-outline" slot="start"></ion-icon>
    <ion-label translate>Logout</ion-label>
  </ion-item>
</ion-content>

<ion-footer class="ion-padding-start ion-padding-end ion-padding-bottom ion-padding-top ion-text-center">
  Got a problem? Email us at
  <a [href]="'mailto:enquiries@' + appName + 'proapp.com'">enquiries@{{ appName }}proapp.com</a> <br />Current version:
  {{ version }}
</ion-footer>
