import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Role, User } from '@lib/shared/models/user.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditContentGuard  {
  constructor(private router: Router, private afAuth: AngularFireAuth, private afs: AngularFirestore) {}
  async canActivate(): Promise<boolean> {
    const auth = await firstValueFrom(this.afAuth.authState);
    const user = await firstValueFrom(this.afs.doc<User>(`users/${auth.uid}`).valueChanges());
    const role = await firstValueFrom(this.afs.doc<Role>(user.role).valueChanges());
    if (role && !role.permissions.editContent) {
      this.router.navigate(['/page-not-found']);
      return false;
    }

    return true;
  }
}
