import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '@lib/environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';

export interface LoginContext {
  username: string;
  password: string;
}

export interface ResetContext {
  username: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loading = new BehaviorSubject(false);
  sharedLoading = this.loading.asObservable();

  private rstPassword = new BehaviorSubject(false);
  sharedResetPassword = this.rstPassword.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private fns: AngularFireFunctions,
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  async login(context: LoginContext): Promise<any> {
    return await this.afAuth.signInWithEmailAndPassword(context.username, context.password);
  }

  async resetPassword(context: ResetContext): Promise<any> {
    return await firstValueFrom(
      this.fns.httpsCallable('sendUserPasswordReset')({
        email: context.username,
        appName: environment.appType === 'outdoor' ? 'outdoorPro' : 'pePro',
      }),
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  async logout(): Promise<Observable<boolean>> {
    // Customize credentials invalidation here
    await this.afAuth.signOut();
    Sentry.configureScope((scope: any) => scope.setUser(null));
    return of(true);
  }

  nextLoading(loading: boolean) {
    this.loading.next(loading);
  }

  nextResetPassword(resetPassword: boolean) {
    this.rstPassword.next(resetPassword);
  }
}
