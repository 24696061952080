<ion-tabs>
  <ion-tab-bar slot="bottom">
    <img class="bar-logo ion-margin-top" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
    <ion-tab-button tab="home">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="home"
          class="svg-inline--fa fa-home fa-w-18"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
          ></path>
        </svg>
      </div>

      <ion-label translate>Home</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="curriculum">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="clipboard"
          class="svg-inline--fa fa-clipboard fa-w-12"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"
          ></path>
        </svg>
      </div>

      <ion-label translate>Curriculum</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="lessons">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="whistle"
          class="svg-inline--fa fa-whistle fa-w-20"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path
            fill="currentColor"
            d="M232 256c0 13.2-10.8 24-24 24s-24-10.8-24-24 10.8-24 24-24 24 10.8 24 24zm252-49.7l150 120c6.7 5.4 8 15.2 2.8 22.1l-78.2 104.3c-4.8 6.5-13.7 8.3-20.7 4.3l-150.4-86C312.1 484.4 150.8 492.8 61 403c-65.5-65.5-78.1-163.8-38-242-14.4-11.8-23-29.7-23-49 0-35.3 28.7-64 64-64 19.2 0 36.9 8.9 49 23.5 65.3-33.6 144.4-30.8 207 9.3l86.6 63.6c5.3 4.2 7.3 11.3 5.1 17.7l-10.2 29.5c-1.1 3.2-.1 6.7 2.6 8.9l25.2 20.2c2.6 2.1 6.2 2.3 9.1.6l27.4-16.2c5.8-3.4 13-2.9 18.2 1.2zM85.5 88.3C79.7 83 72 80 64 80c-17.7 0-32 14.3-32 32 0 8.1 3.3 15.4 8.4 21.2 15.2-20.6 27.3-31.9 45.1-44.9zM280 256c0-39.7-32.3-72-72-72s-72 32.3-72 72 32.3 72 72 72 72-32.3 72-72z"
          ></path>
        </svg>
      </div>

      <ion-label translate>All lessons</ion-label>
    </ion-tab-button>
    <ion-tab-button [tab]="'assessments/' + credentialsService.userLicence.id">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="check-circle"
          class="svg-inline--fa fa-check-circle fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
          ></path>
        </svg>
      </div>

      <ion-label translate>Assessments</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="docs">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="file-alt"
          class="svg-inline--fa fa-file-alt fa-w-12"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
          ></path>
        </svg>
      </div>

      <ion-label translate>Documents</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="settings">
      <div class="icon">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="info-circle"
          class="svg-inline--fa fa-info-circle fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
          ></path>
        </svg>
      </div>

      <ion-label translate>More</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
