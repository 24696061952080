import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { Route, Routes } from '@angular/router';
import { ExpiredLicenceGuard } from '@lib/shared/guards/expiredLicence.guard';

import { ShellComponent } from './pe-pro-shell.component';

/**
 * Provides helper methods to create routes.
 */

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */

  static childRoutes(routes: Routes): Route {
    return {
      path: 'tabs',
      component: ShellComponent,
      children: routes,
      canActivate: [AngularFireAuthGuard, ExpiredLicenceGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true, authGuardPipe: redirectUnauthorizedToLogin },
      // data: { reuse: true },
    };
  }
}
