import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { LicenceExpiredRoutingModule } from './licence-expired-routing.module';
import { LicenceExpiredComponent } from './licence-expired.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, LicenceExpiredRoutingModule],
  declarations: [LicenceExpiredComponent],
})
export class LicenceExpiredModule {}
