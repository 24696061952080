import { Component, OnInit, Input } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
@Sentry.TraceClassDecorator()
export class LoaderComponent implements OnInit {
  @Input() isLoading = false;
  @Input() message: string | undefined;

  constructor() {}

  @Sentry.TraceMethodDecorator()
  ngOnInit() {}
}
