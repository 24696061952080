<form novalidate (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" [formGroup]="form">
  <ion-list>
    <div class="ion-padding-start ion-padding-top">
      <h4>Reset your password</h4>
    </div>
    <ion-item lines="inset">
      <ion-label color="medium" position="floating" translate>New password</ion-label>
      <ion-input type="password" formControlName="password" autocomplete="new-password"></ion-input>
    </ion-item>
    <ion-text color="danger" class="error-message" *ngIf="submitted && form.get('password').hasError('required')">
      <span padding translate>Please enter a password</span>
    </ion-text>
    <ion-text color="danger" class="error-message" *ngIf="submitted && form.get('password').hasError('minlength')">
      <span padding translate>Password must be longer than 8 characters</span>
    </ion-text>
    <ion-text color="danger" class="error-message" *ngIf="submitted && form.get('password').hasError('pattern')">
      <span padding translate>Please must contain a number and a special character</span>
    </ion-text>
    <ion-item lines="inset">
      <ion-label color="medium" position="floating" translate>Confirm new password</ion-label>
      <ion-input type="password" formControlName="confirmPassword" autocomplete="new-password"></ion-input>
    </ion-item>
    <ion-text color="danger" class="error-message" *ngIf="submitted && form.hasError('notSame')">
      <span padding translate>Please make sure both passwords are the same</span>
    </ion-text>
    <ion-text
      color="danger"
      class="error-message"
      *ngIf="submitted && form.get('confirmPassword').hasError('required')"
    >
      <span padding translate>Please confirm your new password</span>
    </ion-text>
    <ion-text
      color="danger"
      class="error-message"
      *ngIf="submitted && form.get('confirmPassword').hasError('minlength')"
    >
      <span padding translate>Please enter a stronger password</span>
    </ion-text>
  </ion-list>
  <ion-button
    type="submit"
    color="primary"
    expand="block"
    class="ion-margin-top ion-margin-start ion-margin-end"
    translate
  >
    Submit
  </ion-button>
</form>
