import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMAIL_PATTERN } from 'lib/core/constants/constants';
import { RegisterService } from 'lib/core/register.service';
import * as Sentry from '@sentry/angular-ivy';
import { ToastService } from '@lib/shared/services/toast.service';
import { DeviceService } from '@lib/core/device.service';
import { Browser } from '@capacitor/browser';
import { environment } from '@lib/environments/environment';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
@Sentry.TraceClassDecorator()
export class CreateUserComponent {
  public form: UntypedFormGroup;
  public submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private toastService: ToastService,
    private deviceService: DeviceService,
  ) {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
        ],
      ],
      termsPrivacy: [false, [Validators.required, Validators.requiredTrue]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.registerService.user$.next(this.form.value);
      this.router.navigate(['/create-account', 'create-licence']);
    }
  }

  async openPage(urls: string[]): Promise<void> {
    try {
      const url = environment.appType === 'pe' ? urls[0] : urls[1];
      this.deviceService.isWeb ? window.open(url, '_blank') : await Browser.open({ url });
    } catch (error) {
      console.error(error);
      this.toastService.showDissmissableErrorToast('Error opening page');
    }
  }
}
