import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentReference,
  Query,
} from '@angular/fire/compat/firestore';
import { environment } from '@lib/environments/environment';
import { Observable } from 'rxjs';
import { Block, ContentPage, ListBlock, Row } from '../models/content.model';
import { AdminSettingsService } from './admin-settings.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private contentCollection = environment.appType === 'pe' ? 'content' : 'outdoorProContent';
  private collection = environment.appType === 'pe' ? 'peProCollections' : 'outdoorProCollections';

  constructor(
    private afs: AngularFirestore,
    private adminSettingsService: AdminSettingsService,
  ) {}

  async addContentPage(route: ActivatedRouteSnapshot): Promise<DocumentReference> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    const id = this.afs.createId();
    const contentCollection = this.afs.collection<ContentPage>(`${this.collection}/${collectionId}/content`);
    const newContentPage = {
      archived: false,
    };
    contentCollection.doc(id).set(newContentPage);
    return this.afs.doc<ContentPage>(`${this.collection}/${collectionId}/content/${id}`).ref;
  }

  async addRow(contentId: string, row: Row, route: ActivatedRouteSnapshot): Promise<string> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    const id = this.afs.createId();
    await this.afs.doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${id}`).set(row);
    return id;
  }

  async deleteRow(contentId: string, rowId: string, route: ActivatedRouteSnapshot): Promise<void> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return await this.afs.doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}`).delete();
  }

  async editRow(contentId: string, rowId: string, row: any, route: ActivatedRouteSnapshot): Promise<void> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return await this.afs.doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}`).update(row);
  }

  async getRowsQuery(
    contentId: string,
    route: ActivatedRouteSnapshot,
    keyStageId?: string,
  ): Promise<AngularFirestoreCollection<Row>> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    const keyStageDocRef = keyStageId
      ? this.afs.doc<ListBlock>(`${this.collection}/${collectionId}/keystages/${keyStageId}`).ref
      : null;
    return this.afs.collection<Row>(`${this.collection}/${collectionId}/content/${contentId}/rows`, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.orderBy('order');
      if (keyStageId) {
        query = query.where('keyStages', 'array-contains', keyStageDocRef);
      }

      return query;
    });
  }

  async getRows(contentId: string, route: ActivatedRouteSnapshot, keyStageId?: string): Promise<Observable<Row[]>> {
    return (await this.getRowsQuery(contentId, route, keyStageId)).valueChanges({ idField: 'id' });
  }

  async addBlock(contentId: string, rowId: string, block: Block, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);

    const id = this.afs.createId();
    await this.afs.doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}/blocks/${id}`).set(block);
    return id;
  }

  async editBlock(
    contentId: string,
    rowId: string,
    blockId: string,
    block: any,
    route: ActivatedRouteSnapshot,
  ): Promise<void> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);

    delete block.id;
    return await this.afs
      .doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}/blocks/${blockId}`)
      .update(block);
  }

  async resetBlock(
    contentId: string,
    rowId: string,
    blockId: string,
    block: Block,
    route: ActivatedRouteSnapshot,
  ): Promise<void> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);

    const order = block.order;
    return await this.afs
      .doc(`${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}/blocks/${blockId}`)
      .set({
        type: 'empty',
        order,
      });
  }

  async getBlocksQuery(
    contentId: string,
    rowId: string,
    route: ActivatedRouteSnapshot,
  ): Promise<AngularFirestoreCollection<Block>> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<Block>(
      `${this.collection}/${collectionId}/content/${contentId}/rows/${rowId}/blocks`,
      (ref) => {
        let query: CollectionReference | Query = ref;
        query = query.orderBy('order');
        return query;
      },
    );
  }

  async getBlocks(contentId: string, rowId: string, route: ActivatedRouteSnapshot): Promise<Observable<Block[]>> {
    return (await this.getBlocksQuery(contentId, rowId, route)).valueChanges({ idField: 'id' });
  }

  getVideoUrl(videoId: string): string {
    return environment.cloudflareVideoUrl + '/' + videoId + '/manifest/video.m3u8';
  }
}
