import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@lib/shared';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { MomentModule } from 'ngx-moment';
import { SettingsLicenceComponent } from './licence/licence.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LicenceResolver } from '@lib/shared/resolvers/licence.resolver';
import { ContentResolver } from '@lib/shared/resolvers/content.resolver';
import { KeystagesResolver } from '@lib/shared/resolvers/keystage.resolver';
import { EnabledKeystagesResolver } from '@lib/shared/resolvers/enabledKeystages.resolver';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    IonicModule,
    SettingsRoutingModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [SettingsComponent, SettingsLicenceComponent],
  providers: [LicenceResolver, SharedModule, ContentResolver, KeystagesResolver, EnabledKeystagesResolver],
})
export class SettingsModule {}
