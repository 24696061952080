import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ListBlock } from '../models/content.model';
import { ListService } from '../services/list.service';
import { firstValueFrom } from 'rxjs';
import { AdminSettingsService } from '../services/admin-settings.service';

@Injectable()
export class KeystagesResolver {
  constructor(
    private router: Router,
    private listService: ListService,
    private adminSettingsService: AdminSettingsService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<ListBlock[]> | null {
    try {
      const collectionId = await this.adminSettingsService.getCollectionId(route);

      return await firstValueFrom(
        this.listService
          .getKeystagesQuery(collectionId)
          .get()
          .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
      );
    } catch (error) {
      console.error(error);
      this.router.navigate(['/problem-with-the-service']);
      return Promise.reject();
    }
  }
}
