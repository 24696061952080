import { Component } from '@angular/core';
import { CredentialsService } from '@lib/core';
import * as Sentry from '@sentry/angular-ivy';
@Component({
  selector: 'app-pe-pro-shell',
  templateUrl: './pe-pro-shell.component.html',
  styleUrls: ['./pe-pro-shell.component.scss'],
})
@Sentry.TraceClassDecorator()
export class ShellComponent {
  constructor(public credentialsService: CredentialsService) {}
}
