<div class="ion-margin-bottom header">
  <h2>Licence Users</h2>
  <div class="ion-padding-end">
    <ion-button
      *ngIf="credentialsService?.userRole?.name == 'Licence holder' || credentialsService?.userRole?.name == 'Admin'"
      class="ion-margin-top ion-margin-end"
      color="primary"
      type="button"
      size="medium"
      (click)="addUser('user')"
      >Add User</ion-button
    >
    <ion-button
      class="ion-margin-top"
      color="primary"
      type="button"
      size="medium"
      (click)="addUser('licenceHolder')"
      *ngIf="credentialsService?.userRole?.name == 'Admin'"
      >Add Licence Holder</ion-button
    >
  </div>
</div>

<ion-card>
  <ion-card-content class="user-list">
    <div class="loading-text" *ngIf="loadingUsers">Loading</div>

    <ng-container *ngFor="let user of licenceUsers">
      <ion-item *ngIf="user">
        <div class="user-wrapper">
          <ion-label
            >{{ user.name }} ({{ user.email }})&nbsp;<ion-chip *ngIf="user.role.id == 'licenceHolder'"
              >Licence holder</ion-chip
            >
          </ion-label>

          <div class="button-wrap" *ngIf="user.id !== credentialsService.currentUserId">
            <ion-button
              *ngIf="user.role.id == 'licenceHolder' && credentialsService?.userRole?.name == 'Admin'"
              size="medium"
              color="warning"
              (click)="changeUserPerms(user, 'user')"
              >Change to user</ion-button
            >
            <ion-button
              *ngIf="user.role.id == 'user' && credentialsService?.userRole?.name == 'Admin'"
              size="medium"
              color="warning"
              (click)="changeUserPerms(user, 'licenceHolder')"
              >Change to licence holder</ion-button
            >
            <ion-button
              *ngIf="
                credentialsService?.userRole?.name == 'Licence holder' || credentialsService?.userRole?.name == 'Admin'
              "
              size="medium"
              color="danger"
              (click)="confirmDelete(user.name, user.id)"
              >Remove</ion-button
            >
          </div>
        </div>
      </ion-item>
    </ng-container>
  </ion-card-content>
</ion-card>
