import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CredentialsService } from '@lib/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private alertController: AlertController, private credentialsService: CredentialsService) {}

  public async areYouSureAlert(message: string): Promise<boolean> {
    let userSure: boolean;
    const alert = await this.alertController.create({
      header: 'Are you sure?',
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => (userSure = false),
        },
        {
          text: 'Okay',
          handler: () => (userSure = true),
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
    return userSure;
  }

  private async showTrialAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Locked for trial users',
      message: 'Some features are not available for trial users.',
      buttons: [
        {
          text: 'Okay',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  public async isTrial(logic: any, showOnTrial: boolean): Promise<void> {
    if (this.credentialsService.userLicence?.trial && showOnTrial !== true) {
      this.showTrialAlert();
    } else {
      await logic();
    }
  }
}
