<app-header title="Add new licence holder" [showBack]="false">
  <ion-button button color="light" class="ion-margin-end" slot="end" (click)="dismissModal()">Close</ion-button>
</app-header>
<ion-content class="ion-padding">
  <form novalidate (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" [formGroup]="form">
    <ion-item>
      <ion-label position="stacked">Name</ion-label>
      <ion-input [formControlName]="'name'" type="text" id="name" name="name"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Email</ion-label>
      <ion-input [formControlName]="'email'" type="email" id="email" name="email"></ion-input>
    </ion-item>
    <ion-button
      class="ion-margin-top ion-margin-start"
      *ngIf="!submitting"
      color="success"
      type="submit"
      [disabled]="form.invalid"
      >Add</ion-button
    >
    <p class="ion-margin-start" *ngIf="submitting">Please Wait</p>
  </form>
</ion-content>
