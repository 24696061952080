import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Licence, LicenceHolder, Role, User } from '@lib/shared/models/user.model';
import { UserCredential } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';

import { LoginContext } from './authentication/authentication.service';
import { LicenceService } from '../shared/services/licence.service';
import { UserService } from '../shared/services/user.service';
import { environment } from '@lib/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  public user$: BehaviorSubject<User> = new BehaviorSubject(null);
  public licence$: BehaviorSubject<LicenceHolder> = new BehaviorSubject(null);
  public signUpMethod$: BehaviorSubject<string> = new BehaviorSubject(null);
  private licenceHolder: AngularFirestoreDocument<Role>;

  constructor(
    private auth: AngularFireAuth,
    private userService: UserService,
    private afs: AngularFirestore,
    private licenceService: LicenceService,
  ) {
    this.licenceHolder = this.afs.doc('/roles/licenceHolder');
  }

  public async createAccount(trial = true): Promise<void> {
    const createAuth = await this.createFirebaseAuth({
      username: this.user$.value.email,
      password: this.user$.value.password,
    });

    const now = new Date();
    const expiry = new Date(new Date().setDate(now.getDate() + 30));

    const licenceUid = await this.createLicence(createAuth, now, expiry, trial);

    await this.createUser(licenceUid, createAuth, now);
  }

  private async createFirebaseAuth(context: LoginContext): Promise<any> {
    return await this.auth.createUserWithEmailAndPassword(context.username, context.password);
  }

  private async createUser(licenceUid: string, createAuth: UserCredential, now: Date) {
    const licenceDoc = this.afs.doc(`/licences/${licenceUid}`);

    const user: User = {
      ...this.user$.value,
      createdAt: now,
      role: this.licenceHolder.ref,
      licence: licenceDoc.ref,
      signUpMethod: this.signUpMethod$.value,
    };
    delete user.password;
    await this.userService.createUser(createAuth.user.uid, user);
  }

  private async createLicence(createAuth: UserCredential, now: Date, expiry: Date, trial: boolean): Promise<string> {
    const userDoc = this.afs.doc(`/users/${createAuth.user.uid}`);
    const licence: Licence = {
      licenceHolder: {
        ...this.licence$.value,
        name: this.user$.value.name,
        email: this.user$.value.email,
      },
      archived: false,
      pePro: environment.appType === 'pe',
      outdoorPro: environment.appType === 'outdoor',
      validFrom: now,
      expiresAt: expiry,
      outdoorProValidFrom: now,
      outdoorProExpiresAt: expiry,
      updatedAt: now,
      updatedBy: createAuth.user.uid,
      expiresAtDate: expiry,
      trial,
      outdoorProTrial: trial,
      users: [userDoc.ref],
    };

    return await this.licenceService.createLicence(licence);
  }
}
