import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { Licence, LicenceId, LicenceSettings, User } from '@lib/shared/models/user.model';
import { Observable } from 'rxjs';

import { CredentialsService } from '../../core/authentication/credentials.service';

@Injectable({
  providedIn: 'root',
})
export class LicenceService {
  constructor(private afs: AngularFirestore, private credentialsService: CredentialsService) {}

  public async createLicence(licenceDetails: Licence): Promise<any> {
    const uid = this.afs.createId();
    await this.afs.doc(`licences/${uid}`).set(licenceDetails);
    return uid;
  }
  public getLicenceDocument(licenceId: string): AngularFirestoreDocument<Licence> {
    return this.afs.doc<Licence>(`licences/${licenceId}`);
  }

  public getLicence(licenceId: string): Observable<LicenceId> {
    return this.getLicenceDocument(licenceId).valueChanges({ idField: 'id' });
  }

  public async updateLicenceSettings(uid: string, licenceSettings: LicenceSettings): Promise<any> {
    return await this.afs.doc(`licences/${uid}`).update({
      settings: licenceSettings,
    });
  }

  public updateLicence(licenceId: string, data: any) {
    const licenceDoc = this.afs.doc<Licence>(`licences/${licenceId}`);
    licenceDoc.update(data);
  }

  public async getLicenceUsers(licenceId: string) {
    const licenceDocRef = this.afs.doc<Licence>(`licences/${licenceId}`).ref;
    const userCollection = this.afs.collection<User>(`users`, (ref) => {
      let query: any = ref;
      query = query.where('licence', '==', licenceDocRef);
      query = query.orderBy('role');
      return query;
    });

    return userCollection.valueChanges({ idField: 'id' });
  }

  public getAllLicences(trial: boolean): Observable<LicenceId[]> {
    const licenceCollection = this.afs.collection<LicenceId>(`licences`, (ref) => {
      let query: any = ref;
      query = query.where('archived', '==', false);
      if (trial) query = query.where('trial', '==', trial);
      query = query.orderBy('licenceHolder.company');
      return query;
    });

    return licenceCollection.valueChanges({ idField: 'id' });
  }

  public getLicenceRef(licenceId: string): DocumentReference {
    return this.afs.doc<Licence>(`licences/${licenceId}`).ref;
  }
}
