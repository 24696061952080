import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
@Sentry.TraceClassDecorator()
export class AuthComponent {
  public mode = this.activatedActivated.snapshot.queryParams['mode'];

  constructor(private activatedActivated: ActivatedRoute) {}
}
