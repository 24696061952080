<ion-content class="ion-padding-top" no-bounce>
  <div class="ion-text-center ion-margin-top ion-padding ion-margin-bottom">
    <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
  </div>
  <div class="expired-message ion-text-center ion-padding">
    <h1>Your licence has expired</h1>
    <p class="ion-margin-bottom">Please get in touch with us to discuss your licence</p>

    <ion-button class="ion-margin-top" (click)="logout()">Logout</ion-button>
  </div>
</ion-content>
