import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as base64 from 'base-64';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
@Sentry.TraceClassDecorator()
export class HeaderComponent {
  @Input() public title: string;
  @Input() public showBack: boolean = true;
  @Input() public defaultBackUrl: string;

  constructor(private route: ActivatedRoute) {
    this.title = this.route.snapshot.queryParams?.title
      ? base64.decode(this.route.snapshot.queryParams.title)
      : this.route.snapshot?.data?.title
      ? this.route.snapshot.data.title
      : '';
    this.defaultBackUrl = this.route.snapshot.data?.defaultBackUrl;
  }
}
