import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/pePro/pe-pro-shell/pe-pro-shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'curriculum',
      loadChildren: () => import('./curriculum/curriculum.module').then((m) => m.CurriculumModule),
      runGuardsAndResolvers: 'always',
    },
    {
      path: 'lessons',
      loadChildren: () => import('./lessons/lessons.module').then((m) => m.LessonsModule),
    },
    { path: 'docs', loadChildren: () => import('@lib/docs/docs.module').then((m) => m.DocsModule) },
    {
      path: 'assessments',
      redirectTo: `/tabs/assessments/${localStorage.getItem('licenceId')}`,
      pathMatch: 'full',
    },
    {
      path: 'assessments/:licenceId',
      loadChildren: () => import('./assessments/assessments.module').then((m) => m.AssessmentsModule),
    },
  ]),
  {
    path: 'problem-with-the-service',
    loadChildren: () =>
      import('@lib/problem-with-the-service/problem-with-the-service.module').then(
        (m) => m.ProblemWithTheServiceModule,
      ),
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('@lib/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
  {
    path: 'no-licence',
    loadChildren: () => import('@lib/no-app-access/no-app-access.module').then((m) => m.NoAppAccessModule),
  },
  // Fallback when no prior route is matched
  { path: '', redirectTo: 'tabs/home', pathMatch: 'full' },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
