<form (ngSubmit)="login()" (keyup.enter)="login()" [formGroup]="loginForm" data-testid="login-form">
  <ion-list>
    <ion-item lines="inset">
      <ion-label color="medium" position="floating" translate>Email address</ion-label>
      <ion-input type="text" formControlName="username" autocomplete="username"></ion-input>
      <ion-note slot="error" *ngIf="loginForm.get('username').dirty && loginForm.get('username')?.hasError('required')">
        Email address is required
      </ion-note>
      <ion-note slot="error" *ngIf="loginForm.get('username').dirty && loginForm.get('username')?.hasError('email')">
        Email address is not a valid email address
      </ion-note>
    </ion-item>
    <ion-item lines="inset">
      <ion-label color="medium" position="floating" translate>Password</ion-label>
      <ion-input type="password" formControlName="password" autocomplete="current-password"></ion-input>
      <ion-note slot="error" *ngIf="loginForm.get('password').dirty && loginForm.get('password')?.hasError('required')">
        Password is required
      </ion-note>
    </ion-item>
  </ion-list>
  <div class="login-button-container" padding-horizontal>
    <ion-button type="submit" [disabled]="loginForm.invalid" color="primary" expand="block" translate>
      Login
    </ion-button>
    <ion-button
      *ngIf="!isIos"
      routerLink="/create-account/create-user"
      color="primary"
      expand="block"
      class="ion-margin-top"
      data-testid="create-account-button"
    >
      Start a free trial
    </ion-button>
    <ion-item class="forgotten-password">
      <a (click)="showResetPassword()" color="medium" translate>Don't know your password?</a>
    </ion-item>
  </div>
</form>
