import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '@lib/environments/environment';
import { Licence, User } from '@lib/shared/models/user.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppAccessGuard  {
  constructor(private router: Router, private afAuth: AngularFireAuth, private afs: AngularFirestore) {}

  async canActivate(): Promise<boolean> {
    const auth = await firstValueFrom(this.afAuth.authState);
    const userDoc = this.afs.doc<User>(`users/${auth.uid}`);
    const user = await firstValueFrom(userDoc.valueChanges());
    const licence = await firstValueFrom(this.afs.doc<Licence>(user.licence).valueChanges());

    const fieldName = environment.appType === 'pe' ? 'pePro' : 'outdoorPro';

    if (licence && !licence[fieldName]) {
      this.router.navigate(['/no-licence']);
      return false;
    }

    return true;
  }
}
