import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentReference,
  Query,
} from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@lib/environments/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ListBlock } from '../models/content.model';
import { AdminSettingsService } from './admin-settings.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private collection = environment.appType === 'pe' ? 'peProCollections' : 'outdoorProCollections';
  constructor(
    private afs: AngularFirestore,
    private adminSettingsService: AdminSettingsService,
  ) {}

  private getPath(route: ActivatedRouteSnapshot): string {
    const routeUrl = route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()))
      .filter((arr) => arr.length)
      .flat(); // this gets all segments and returns an array of strings
    if (routeUrl.includes('lessons')) {
      routeUrl.splice(0, routeUrl.indexOf('lessons') + 2); // Both all lessons screen and admin screen have lessons in the URL, but the next page differs
    } else {
      routeUrl.splice(0, routeUrl.indexOf('key-stages') + 2); // Both all lessons screen and admin screen have lessons in the URL, but the next page differs
    }
    if (routeUrl[0] === 'list') {
      routeUrl.splice(0, 1); // on the all lessons screen, list is in the URL, which we don't want in the db
    }
    const path = routeUrl.join('/'); // join to make a string ready for db url
    return path;
  }
  public async getLessonsDatabasePath(
    route: ActivatedRouteSnapshot,
    isKeyStage = false,
  ): Promise<{
    collectionRef: string;
    keyStageId: string;
  }> {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    const path = this.getPath(route);
    const collectionRef = () => {
      if (isKeyStage) {
        return `${this.collection}/${collectionId}/keystages`;
      } else {
        return `${this.collection}/${collectionId}/lessons${path.length ? '/' + path + '/list' : ''}`;
      }
    };
    return { collectionRef: collectionRef(), keyStageId: route.paramMap.get('keyStage') };
  }

  getDocFromId(ref: string): Observable<any> {
    const listBlockDoc = this.afs.doc<any>(ref);

    return listBlockDoc.get().pipe(map((a) => ({ id: a.id, ...a.data() })));
  }

  getKeystagesQuery(collectionId: string): AngularFirestoreCollection<ListBlock> {
    return this.afs.collection<ListBlock>(`${this.collection}/${collectionId}/keystages`, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.where('archived', '==', false);
      query = query.orderBy('order');
      return query;
    });
  }

  async getKeystageRefs(route: ActivatedRouteSnapshot): Promise<DocumentReference[]> {
    return await firstValueFrom(
      this.getKeystagesQuery(await this.adminSettingsService.getCollectionId(route))
        .get()
        .pipe(map((snapshot) => snapshot.docs.map((a) => a.ref))),
    );
  }

  public async getEnabledKeystages(route: ActivatedRouteSnapshot): Promise<DocumentReference[]> {
    let listBlock;
    try {
      const listBlockCollection = (await this.getLessonsDatabasePath(route)).collectionRef;
      const listBlockRef = listBlockCollection.substring(0, listBlockCollection.lastIndexOf('/')).replace('/edit', '');
      listBlock = (await firstValueFrom(this.getDocFromId(listBlockRef))) as ListBlock;
      if (listBlock?.keyStages) {
        return listBlock.keyStages;
      } else {
        return this.getKeystageRefs(route);
      }
    } catch (error) {
      console.error(error);
      return this.getKeystageRefs(route);
    }
  }
}
