import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Licence, LicenceId, Role, User } from '@lib/shared/models/user.model';
import { ToastService } from '@lib/shared/services/toast.service';
import * as Sentry from '@sentry/angular-ivy';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private userDocument: AngularFirestoreDocument<User>;
  private roleDocument: AngularFirestoreDocument<Role>;
  private licenceDocument: AngularFirestoreDocument<Licence>;
  private authState: any = null;
  private userDetails: User;
  private roleDetails: Role;
  private licenceDetails: LicenceId;

  constructor(
    private afs: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private toastService: ToastService,
  ) {
    this.firebaseAuth.authState.subscribe({
      next: async (authState) => {
        this.authState = authState;
        if (this.authState !== null) {
          await this.getUserData();
        }
      },
      error: (error) => {
        console.error(error);
        this.toastService.showDissmissableErrorToast(
          'Error loading firebase authentication. Please log out and login again',
        );
      },
    });
  }

  get isAuthenticated(): boolean {
    return this.authState !== null;
  }

  get currentUserId(): string {
    return this.isAuthenticated ? this.authState.uid : null;
  }

  get userData(): User {
    return {
      id: this.authState?.uid || null,
      email: this.authState?.email || null,
      name: this.userDetails?.name || '',
      createdAt: this.userDetails?.createdAt || null,
      updatedAt: this.userDetails?.updatedAt || null,
      licence: this.userDetails?.licence || null,
      role: this.userDetails?.role || null,
    };
  }

  get userRole(): Role {
    return this.roleDetails;
  }

  get token(): Observable<string> {
    return this.firebaseAuth.idToken;
  }

  get userLicence(): Licence | any {
    return this.licenceDetails ? this.licenceDetails : { id: '' };
  }

  async getUserData(): Promise<void> {
    this.userDocument = this.afs.doc<User>(`users/${this.authState.uid}`);
    this.userDetails = await firstValueFrom(this.userDocument.valueChanges({ idField: 'id' }));

    Sentry.setUser({
      id: this.currentUserId ? this.currentUserId : null,
    });

    this.roleDocument = this.afs.doc<Role>(this.userDetails?.role);
    this.roleDetails = await firstValueFrom(this.roleDocument.valueChanges({ idField: 'id' }));

    Sentry.setContext('role', {
      name: this.roleDetails && this.roleDetails?.name ? this.roleDetails?.name : null,
    });

    this.licenceDocument = this.afs.doc<Licence>(this.userDetails?.licence);
    this.licenceDetails = await firstValueFrom(this.licenceDocument.valueChanges({ idField: 'id' }));

    localStorage.setItem('licenceId', this.licenceDetails.id);
    localStorage.setItem('userId', this.userDetails.id);
  }
}
