import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Term } from '../models/curriculum.model';
import { CurriculumService } from '../services/curriculum.service';

@Injectable()
export class TermsResolver {
  constructor(
    private curriculumService: CurriculumService,
    private router: Router,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<null | Term[]> {
    try {
      const yearId = route.paramMap.get('yearId');
      return await this.curriculumService.getTerms(yearId, route);
    } catch (error) {
      console.error(error);
      this.router.navigate(['/problem-with-the-service']);
      return Promise.reject();
    }
  }
}
