import { Component, Input } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-icon-block',
  templateUrl: './icon-block.component.html',
  styleUrls: ['./icon-block.component.scss'],
})
@Sentry.TraceClassDecorator()
export class IconBlockComponent {
  @Input() public title: string;
  @Input() public route: string[];
  @Input() public iconName?: string;

  constructor() {}
}
