<div class="icon-block">
  <div class="icon-block__icon">
    <ng-container *ngIf="!iconName; else icon">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #icon>
      <ion-icon [icon]="icon" />
    </ng-template>
  </div>
  <strong class="icon-block__text">
    {{ title }}
    <ion-icon name="chevron-forward-outline" />
  </strong>
</div>
