<ion-content no-bounce>
  <div class="login-background"></div>
  <ion-grid no-padding>
    <ion-row justify-content-center no-padding style="justify-content: center">
      <ion-col size-sm="8" size-lg="6" size-xl="4" align-self-center no-padding>
        <div class="login-container-wrapper">
          <form novalidate (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" [formGroup]="form">
            <ion-list>
              <ion-item-divider>
                <ion-label> School details </ion-label>
              </ion-item-divider>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School name</ion-label>
                <ion-input
                  data-testid="company-input"
                  type="text"
                  formControlName="company"
                  autocomplete="organization"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="company-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('company').hasError('required')"
              >
                <span padding translate>Please enter the school name</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School Address line 1</ion-label>
                <ion-input
                  data-testid="address1-input"
                  type="text"
                  formControlName="address1"
                  autocomplete="address-line1"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="address1-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('address1').hasError('required')"
              >
                <span padding translate>Please enter the first line of the schools address</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School Address line 2</ion-label>
                <ion-input
                  data-testid="address2-input"
                  type="text"
                  formControlName="address2"
                  autocomplete="address-line2"
                ></ion-input>
              </ion-item>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School Address line 3</ion-label>
                <ion-input
                  data-testid="address3-input"
                  type="text"
                  formControlName="address3"
                  autocomplete="address-line3"
                ></ion-input>
              </ion-item>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School Town or City</ion-label>
                <ion-input
                  data-testid="townCity-input"
                  type="text"
                  formControlName="townCity"
                  autocomplete="address-level2"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="townCity-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('townCity').hasError('required')"
              >
                <span padding translate>Please enter the school town or city</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>School Postcode</ion-label>
                <ion-input
                  data-testid="postcode-input"
                  type="text"
                  formControlName="postcode"
                  autocomplete="postal-code"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="postcode-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('postcode').hasError('required')"
              >
                <span padding translate>Please enter the school postcode</span>
              </ion-text>
              <ion-text
                data-testid="postcode-pattern-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('postcode').hasError('pattern')"
              >
                <span padding translate>Please enter a valid school postcode</span>
              </ion-text>
              <ion-item-divider class="ion-margin-top">
                <ion-label> Your details </ion-label>
              </ion-item-divider>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>Job Title</ion-label>
                <ion-input data-testid="jobTitle-input" type="text" formControlName="jobTitle"></ion-input>
              </ion-item>
              <ion-text
                data-testid="jobTitle-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('jobTitle').hasError('required')"
              >
                <span padding translate>Please enter your job title</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>Mobile number</ion-label>
                <ion-input data-testid="phone-input" type="tel" formControlName="phone" autocomplete="tel"></ion-input>
              </ion-item>
              <ion-text
                data-testid="phone-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('phone').hasError('required')"
              >
                <span padding translate>Please enter your phone number</span>
              </ion-text>
              <ion-text
                data-testid="phone-pattern-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('phone').hasError('pattern')"
              >
                <span padding translate>Please enter a valid phone number</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>How did you hear about us?</ion-label>
                <ion-select data-testid="howHeard-input" formControlName="howHeard">
                  <ion-select-option>PE Pro website</ion-select-option>
                  <ion-select-option>Google</ion-select-option>
                  <ion-select-option>Facebook</ion-select-option>
                  <ion-select-option>LinkedIn</ion-select-option>
                  <ion-select-option>Twitter</ion-select-option>
                  <ion-select-option>Word of mouth</ion-select-option>
                  <ion-select-option>Other</ion-select-option>
                </ion-select>
              </ion-item>
              <ion-text
                data-testid="howHeard-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('howHeard').hasError('required')"
              >
                <span padding translate>Please enter how you heard about us</span>
              </ion-text>
              <ion-item lines="inset" *ngIf="form.get('howHeard').value === 'Other'">
                <ion-label color="medium" position="floating" translate>
                  Other (Please specify where you heard about us)
                </ion-label>
                <ion-input data-testid="howHeardOther-input" type="text" formControlName="howHeardOther"></ion-input>
              </ion-item>
              <ion-text
                data-testid="howHeardOther-required-error"
                color="danger"
                class="error-message"
                *ngIf="
                  submitted && form.get('howHeard').value === 'Other' && form.get('howHeardOther').hasError('required')
                "
              >
                <span padding translate>Please enter how you heard about us</span>
              </ion-text>
            </ion-list>
            <ion-button routerLink="/login" color="danger" expand="block" class="ion-margin-top" translate>
              Cancel
            </ion-button>
            <ion-button
              data-testid="next-button-step2"
              type="submit"
              color="primary"
              expand="block"
              class="ion-margin-top"
              translate
            >
              Submit
            </ion-button>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
