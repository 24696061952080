import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutingService } from '../services/routing.service';

@Injectable()
export class EnabledKeystagesResolver {
  constructor(
    private router: Router,
    private routingService: RoutingService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      return await this.routingService.getEnabledKeystages(route);
    } catch (error) {
      console.error(error);
      this.router.navigate(['/problem-with-the-service']);
      return Promise.reject();
    }
  }
}
