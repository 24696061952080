// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  envName: 'production',
  hmr: false,
  version: env.npm_package_version,
  name: 'PE PRO',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  serverUrl: 'https://peproapp.com',
  appType: 'pe',
  firebase: {
    apiKey: 'AIzaSyAhEhhVKSpuv-uc-vrpfacJVm38dVWL7os',
    authDomain: 'ekc-app.firebaseapp.com',
    databaseURL: 'https://ekc-app.firebaseio.com',
    projectId: 'ekc-app',
    storageBucket: 'ekc-app.appspot.com',
    messagingSenderId: '288907562496',
    appId: '1:288907562496:web:584019307cb3119f8d52e5',
    measurementId: 'G-54C8LSXZKG',
  },
  csvBox: {
    apiKey: 'YyDEemnX2lXvhBAFyXH9LBqrZWLJHX',
  },
  sentryDSN: 'https://31b3ef3b1d104adcb7f5b3c9abb9b17b@o440945.ingest.sentry.io/5410648',
  previewUrlsRegex: /^https:\/\/(ekc-staging|ekc-app)--.*\.web\.app/,
  stagingUrlsRegex: /^https:\/\/.*\.(pepro\.app|onlinepe\.co\.uk)/,
  productionUrls: ['https://pepro.app', 'https://onlinepe.co.uk'],
  sentryProject: 'pe-pro',
  cloudflareVideoUrl: 'https://customer-w24ytoxcxz7ffbty.cloudflarestream.com',
  configCatSDK: '_aPbCFYpVEqKc3IjdtsJ4w/fb7grccpWk6e4Sui37JcSQ',
  useEmulators: false,
};
