import { Component } from '@angular/core';
import { AuthenticationService } from '@lib/core';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { environment } from '@lib/environments/environment';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
@Sentry.TraceClassDecorator()
export class LoginComponent {
  public version: string | null = environment.version;
  public isLoading = false;
  public resetPassword = false;

  constructor(public credentialsService: CredentialsService, private authenticationService: AuthenticationService) {
    this.authenticationService.sharedLoading.subscribe((loading) => (this.isLoading = loading));
    this.authenticationService.sharedResetPassword.subscribe((resetPassword) => (this.resetPassword = resetPassword));
  }
}
