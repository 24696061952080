<ion-content no-bounce>
  <div class="login-background"></div>
  <ion-grid no-padding>
    <ion-row justify-content-center no-padding style="justify-content: center">
      <ion-col size-sm="8" size-lg="6" size-xl="4" align-self-center no-padding>
        <div class="login-header" class="ion-text-center">
          <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
        </div>
        <div class="login-container-wrapper">
          <ng-container [ngSwitch]="mode">
            <!-- password reset -->
            <ng-container *ngSwitchCase="'resetPassword'">
              <app-reset-password></app-reset-password>
            </ng-container>

            <!-- default action -->
            <ng-container *ngSwitchDefault> </ng-container>
          </ng-container>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
