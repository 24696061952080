import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  CollectionReference,
  DocumentReference,
  Query,
} from '@angular/fire/compat/firestore';
import { LessonPlanTerm, Term, Year } from '../models/curriculum.model';
import { environment } from '@lib/environments/environment';
import { AdminSettingsService } from './admin-settings.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { ListService } from './list.service';
import { CellWidthType } from 'jspdf-autotable';

@Injectable({
  providedIn: 'root',
})
export class CurriculumService {
  private collection = environment.appType === 'pe' ? 'peProCollections' : 'outdoorProCollections';

  constructor(
    private afs: AngularFirestore,
    private adminSettingsService: AdminSettingsService,
    private listService: ListService,
  ) {}

  async updateYear(yearId: string, year: Partial<Year>, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<Year>(`${this.collection}/${collectionId}/years/${yearId}`).update(year);
  }

  async addYear(year: Year, route: ActivatedRouteSnapshot) {
    const id = this.afs.createId();
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<Year>(`${this.collection}/${collectionId}/years/`).doc(id).set(year);
  }

  async archiveYear(yearId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<Year>(`${this.collection}/${collectionId}/years/${yearId}`).update({ archived: true });
  }

  async getYear(yearId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<Year>(`${this.collection}/${collectionId}/years/${yearId}`);
  }

  async getYearData(yearId: string, route: ActivatedRouteSnapshot) {
    return await firstValueFrom(
      (await this.getYear(yearId, route)).get().pipe(map((a) => ({ id: a.id, ...a.data() }))),
    );
  }

  async addTerm(yearId: string, term: Term, route: ActivatedRouteSnapshot) {
    const termsCollection = this.afs.collection<Term>(`years/${yearId}/terms`);
    const id = this.afs.createId();
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<Term>(`${this.collection}/${collectionId}/years/${yearId}/terms/${id}`).set(term);
  }

  async updateTerm(yearId: string, termId: string, term: Partial<Term>, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<Term>(`${this.collection}/${collectionId}/years/${yearId}/terms/${termId}`).update(term);
  }

  async getYearsCollection(route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<Year>(`${this.collection}/${collectionId}/years`, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.where('archived', '==', false);
      query = query.orderBy('order');

      return query;
    });
  }

  async getYears(route: ActivatedRouteSnapshot) {
    return await firstValueFrom(
      (await this.getYearsCollection(route))
        .get()
        .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
    );
  }

  async getTermsCollection(yearId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<Term>(`${this.collection}/${collectionId}/years/${yearId}/terms`, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.orderBy('order');
      query = query.where('archived', '==', false);

      return query;
    });
  }

  async addLessonPlanTerm(yearId: string, term: LessonPlanTerm, route: ActivatedRouteSnapshot) {
    const id = this.afs.createId();
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs
      .doc<LessonPlanTerm>(`${this.collection}/${collectionId}/years/${yearId}/lessonPlanTerms/${id}`)
      .set(term);
  }

  async updateLessonPlanTerm(
    yearId: string,
    termId: string,
    term: Partial<LessonPlanTerm>,
    route: ActivatedRouteSnapshot,
  ) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs
      .doc<LessonPlanTerm>(`${this.collection}/${collectionId}/years/${yearId}/lessonPlanTerms/${termId}`)
      .update(term);
  }

  async getLessonPlanTerms(yearId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<LessonPlanTerm>(
      `${this.collection}/${collectionId}/years/${yearId}/lessonPlanTerms`,
      (ref) => {
        let query: CollectionReference | Query = ref;
        query = query.where('archived', '==', false);
        query = query.orderBy('order');

        return query;
      },
    );
  }

  async getLessonPlanTermRef(yearId: string, termId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.doc<LessonPlanTerm>(`${this.collection}/${collectionId}/years/${yearId}/lessonPlanTerms/${termId}`)
      .ref;
  }

  async getTerms(yearId: string, route: ActivatedRouteSnapshot) {
    const terms = await this.getTermsCollection(yearId, route);
    const termDocs = await firstValueFrom(
      terms.get().pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
    );

    return await Promise.all(
      termDocs.map(async (term) => {
        term.listInfo = await firstValueFrom(
          this.listService
            .getListDocFromRef(term.list)
            .get()
            .pipe(map((a) => ({ id: a.id, ...a.data() }))),
        );
        return term;
      }),
    );
  }

  async getCustomTermsCollection(yearId: string, route: ActivatedRouteSnapshot) {
    const collectionId = await this.adminSettingsService.getCollectionId(route);
    return this.afs.collection<Term>(
      `licences/${localStorage.getItem('licenceId')}/${this.collection}/${collectionId}/years/${yearId}/terms`,
      (ref) => {
        let query: CollectionReference | Query = ref;
        query = query.orderBy('order');
        query = query.where('archived', '==', false);

        return query;
      },
    );
  }

  async getTermInfo(termDocs: Term[], route: ActivatedRouteSnapshot) {
    return await Promise.all(
      termDocs.map(async (term) => {
        term.listInfo = await firstValueFrom(
          this.listService
            .getListDocFromRef(term.list)
            .get()
            .pipe(map((a) => ({ id: a.id, ...a.data() }))),
        );

        term.listInfo.listInfo = await firstValueFrom(
          await this.listService.getListBlocks(`${term.list.path}/list`, route),
        );
        term.lessonPlanTermInfo = term.lessonPlanTerm
          ? await firstValueFrom(
              this.getLessonPlanTermFromRef(term.lessonPlanTerm)
                .get()
                .pipe(map((a) => ({ id: a.id, ...a.data() }))),
            )
          : {
              id: term.id,
              archived: term.archived,
              name: term.name,
              order: String(term.order),
            };
        term.lessonPlanTerm = term.lessonPlanTerm
          ? term.lessonPlanTerm
          : ({ id: term.id } as unknown as DocumentReference<LessonPlanTerm>);
        return term;
      }),
    );
  }

  async getCustomTermsForYear(yearId: string, route: ActivatedRouteSnapshot) {
    const termDocs = await firstValueFrom(
      (await this.getCustomTermsCollection(yearId, route))
        .get()
        .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
    );

    return await this.getTermInfo(termDocs, route);
  }

  getLessonPlanTermFromRef(ref: DocumentReference<LessonPlanTerm>): AngularFirestoreDocument<LessonPlanTerm> {
    return this.afs.doc<LessonPlanTerm>(ref);
  }

  async getTermsForYear(yearId: string, route: ActivatedRouteSnapshot) {
    const termDocs = await firstValueFrom(
      (await this.getTermsCollection(yearId, route))
        .get()
        .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
    );

    return await this.getTermInfo(termDocs, route);
  }

  async getAllTerms(year: Year, route: ActivatedRouteSnapshot) {
    const customTerm = await this.getCustomTermsForYear(year.id, route);
    if (customTerm.length) {
      return customTerm;
    } else {
      return await this.getTermsForYear(year.id, route);
    }
  }

  sortLessonPlanTerms(terms: Term[]) {
    const seen = new Set();
    const lessonPlanTerms = terms
      .map((term: any) => {
        return term.lessonPlanTermInfo;
      })
      .filter((el) => {
        if (el === null) return false;

        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      })
      .sort((a, b) => a.order - b.order);

    return lessonPlanTerms;
  }

  getMatchingTerms(terms: Term[], lessonPlanTerms: LessonPlanTerm[]) {
    return lessonPlanTerms.map((lessonPlanTerm: LessonPlanTerm) => {
      return terms
        .filter((term) => term.lessonPlanTerm.id === lessonPlanTerm.id)
        .map((term) => term)
        .sort((a, b) => a.order - b.order);
    });
  }

  getLongTermPlan(terms: Term[], lessonPlanTerms: LessonPlanTerm[], width: number) {
    const matchingTerms = this.getMatchingTerms(terms, lessonPlanTerms);
    const maxLen = Math.max(...matchingTerms.map((a) => a.length));

    const rows = new Array(maxLen).fill(0).map((_, i) => {
      return [
        {
          content: maxLen > 1 ? `Topic ${String.fromCharCode(97 + i).toUpperCase()}` : 'Topic',
          styles: { cellWidth: 15 as CellWidthType },
        },
        ...matchingTerms.map((term) => ({
          content: term[i]?.listInfo?.title || '',
          styles: {
            cellWidth: ((width - 15) / matchingTerms.length - (matchingTerms.length - 1) * 10) as CellWidthType,
          },
        })),
      ];
    });

    return rows;
  }

  getLessonCount(terms: Term[]) {
    const counts = terms.map((term) => {
      return term.listInfo.listInfo.length;
    });

    const maxLen = Math.max(...counts);

    return maxLen;
  }

  convertToString(str: string) {
    str = str.replace(/<li>/g, '• ');
    str = str.replace(/<\/li>/g, '\n');
    str = str.replace(/<[^>]*>?/gm, '');
    return str;
  }

  getMediumTermPlan(terms: Term[], width: number) {
    const maxLen = this.getLessonCount(terms);

    const rows = terms.map((term) => {
      return [
        {
          content: `${term.name} - ${term.listInfo.title}`,
          styles: { cellWidth: 30 as CellWidthType },
        },
        ...new Array(maxLen).fill(0).map((_, i) => {
          return {
            content:
              term.listInfo.listInfo[i]?.walt && term.listInfo.listInfo[i]?.wilf
                ? `WALT:\n${this.convertToString(term.listInfo.listInfo[i]?.walt || '')}\nWILF:\n${this.convertToString(
                    term.listInfo.listInfo[i]?.wilf || '',
                  )}`
                : '',
            styles: {
              cellWidth: ((width - 30) / maxLen - (maxLen - 1) * 10) as CellWidthType,
              fontSize: 8,
            },
          };
        }),
      ];
    });

    return rows;
  }

  getMediumTermHeadings(terms: Term[]) {
    const maxLen = this.getLessonCount(terms);

    return new Array(maxLen).fill(0).map((_, i) => {
      return `Lesson ${i + 1}`;
    });
  }
}
