import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  public async showDissmissableLockedAlert() {
    this.showDissmissableToast(
      'Locked for trial users. Please contact us to upgrade.',
      'lock-closed-outline',
      'warning'
    );
  }

  public async showDissmissableErrorToast(message: string) {
    this.showDissmissableToast(message, 'warning-outline', 'danger');
  }

  public async showAutoDissmissableErrorToast(
    message: string,
    icon: string,
    color: 'primary' | 'secondary' | 'danger' | 'warning' | 'success' = undefined
  ) {
    this.showDissmissableToast(message, icon, color, 3000);
  }

  public async showDissmissableToast(
    message: string,
    icon: string,
    color: 'primary' | 'secondary' | 'danger' | 'warning' | 'success' = undefined,
    duration: number = undefined
  ): Promise<any> {
    const toast = await this.toastController.create({
      message,
      icon,
      color,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
      duration,
    });

    await toast.present();
    return toast;
  }
}
