import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CredentialsService } from '@lib/core';
import { ToastService } from '@lib/shared/services/toast.service';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-add-licence-holder',
  templateUrl: './add-licence-holder.component.html',
  styleUrls: ['./add-licence-holder.component.scss'],
})
@Sentry.TraceClassDecorator()
export class AddLicenceHolderComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitting: boolean = false;
  private licenceId: string;
  private tokenSub: Subscription = new Subscription();
  private userSub: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    public alertController: AlertController,
    private credentialsService: CredentialsService,
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private toastService: ToastService
  ) {}

  @Sentry.TraceMethodDecorator()
  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
    });
  }

  public async onSubmit() {
    if (this.form.invalid) {
      this.errorMessage('Name and email are required and the email has to be a valid email address.');
      this.submitting = false;
    } else {
      this.submitting = true;
      await this.addLicenceHolder();
    }
  }

  private async addLicenceHolder() {
    this.tokenSub = this.credentialsService.token.subscribe({
      next: (token: string) => {
        this.tokenSub.unsubscribe();
        const data = { ...this.form.value, token, licenceId: this.licenceId };
        const addLicenceHolderFn = this.fns.httpsCallable('addLicenceHolderV2');
        const user = addLicenceHolderFn(data);

        this.userSub = user.subscribe({
          next: () => {
            this.dismissModal(user);
          },
          error: async (error) => await this.addLicenceHolderError(error),
          complete: () => {
            this.submitting = false;
            this.userSub.unsubscribe();
          },
        });
      },
      error: (error) => {
        console.error(error);
        this.toastService.showDissmissableErrorToast('Could not get your auth token. Please try again.');
      },
    });
  }

  private async addLicenceHolderError(error: any) {
    switch (error.code) {
      case 'aborted':
        await this.errorMessage('This email address already has an account.');
        break;
      case 'forbidden':
        await this.errorMessage('You do not have permission to add another user.');
        break;
      default:
        await this.errorMessage('There has been an error. Please try again.');
        break;
    }
    this.dismissModal();
  }

  async errorMessage(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: [
        {
          text: 'Close',
        },
      ],
    });

    await alert.present();
  }

  dismissModal(user?: any) {
    this.modalController.dismiss({
      data: user ? user : null,
    });
  }
}
