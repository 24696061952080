import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@lib/shared';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { AuthComponent } from './auth/auth.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { CreateLicenceComponent } from './create-account/create-licence/create-licence.component';
import { CreateUserComponent } from './create-account/create-user/create-user.component';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ResetPasswordEmailComponent } from './reset-password-email/reset-password-email.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    LoginRoutingModule,
    SharedModule,
  ],
  declarations: [
    LoginComponent,
    CreateUserComponent,
    CreateLicenceComponent,
    AuthComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    LoggedInComponent,
    ResetPasswordEmailComponent,
    LoginFormComponent,
  ],
})
export class LoginModule {}
