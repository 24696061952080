import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@lib/core';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { ToastService } from '@lib/shared/services/toast.service';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-reset-password-email',
  templateUrl: './reset-password-email.component.html',
})
@Sentry.TraceClassDecorator()
export class ResetPasswordEmailComponent {
  public resetForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    private toastService: ToastService,
  ) {
    this.createForm();
  }

  async sendResetPassword() {
    this.authenticationService.nextLoading(true);
    try {
      await this.authenticationService.resetPassword(this.resetForm.value);
      this.resetForm.markAsPristine();
      this.authenticationService.nextLoading(false);
      await this.toastService.showDissmissableToast(
        'Password reset email sent. Once you have reset your password, please return to the app to login',
        'mail-unread-outline',
        'success',
      );
      this.authenticationService.nextResetPassword(false);
    } catch (error: any) {
      console.error(error);
      this.authenticationService.nextLoading(true);
      this.toastService.showDissmissableErrorToast(`Error: ${error.code} - ${error.message}`);
    }
  }

  private createForm() {
    this.resetForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
    });
  }

  public hideResetPassword() {
    this.authenticationService.nextResetPassword(false);
  }
}
