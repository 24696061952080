import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { ListService } from '../services/list.service';
import { RoutingService } from '../services/routing.service';
import * as base64 from 'base-64';
@Injectable()
export class ListResolver {
  constructor(
    private router: Router,
    private listService: ListService,
    private routingService: RoutingService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const blockTitle = route.queryParams?.title ? base64.decode(route.queryParams.title) : '';
      const lessonPlan = route.queryParams?.lessonPlan ? base64.decode(route.queryParams.lessonPlan) : '';
      const spotifyUrl = route.queryParams?.spotifyUrl ? base64.decode(route.queryParams.spotifyUrl) : '';
      const youtubeUrl = route.queryParams?.youtubeUrl ? base64.decode(route.queryParams.youtubeUrl) : '';

      const { collectionRef, keyStageId } = await this.routingService.getLessonsDatabasePath(route, false);
      const alphabetical = route?.data?.admin == true ? true : false;
      const listBlocks = {
        title: blockTitle,
        lessonPlan,
        spotifyUrl,
        youtubeUrl,
        blocks: await firstValueFrom(
          (await this.listService.getListBlocksQuery(collectionRef, route, keyStageId, alphabetical))
            .get()
            .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
        ),
      };

      return listBlocks;
    } catch (error) {
      console.error(error);
      this.router.navigate(['/problem-with-the-service']);
      return Promise.reject();
    }
  }
}
