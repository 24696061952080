import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { Shell as PEProShell } from '@app/pePro/pe-pro-shell/pe-pro-shell.service';
import { Shell as OutdoorProShell } from '@app/outdoorPro/shell/shell.service';
import { LicenceResolver } from '@lib/shared/resolvers/licence.resolver';

import { SettingsLicenceComponent } from './licence/licence.component';
import { environment } from '@lib/environments/environment';
import { AdminGuard } from '@lib/shared/guards/admin.guard';
import { ContentResolver } from '@lib/shared/resolvers/content.resolver';
import { KeystagesResolver } from '@lib/shared/resolvers/keystage.resolver';
import { EnabledKeystagesResolver } from '@lib/shared/resolvers/enabledKeystages.resolver';

const Shell = environment.appType === 'pe' ? PEProShell : OutdoorProShell;

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'settings',
      children: [
        {
          path: '',
          component: SettingsComponent,
          data: {
            title: 'Settings',
          },
        },
        {
          path: 'licence/:licenceId',
          component: SettingsLicenceComponent,
          data: {
            title: 'Licence settings',
            defaultBackUrl: '/tabs/settings',
          },
          resolve: {
            licence: LicenceResolver,
          },
        },
        {
          path: 'admin',
          loadChildren: () => import('@lib/admin/admin.module').then((m) => m.AdminModule),
          canActivate: [AdminGuard],
        },
        {
          path: 'c/:contentId',
          loadChildren: () => import('../content-page/content-page.module').then((m) => m.ContentPageModule),
          resolve: {
            content: ContentResolver,
            keyStages: KeystagesResolver,
            enabledKeystages: EnabledKeystagesResolver,
          },
          data: {
            hideKeystages: true,
          },
          runGuardsAndResolvers: 'always',
        },
      ],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SettingsRoutingModule {}
