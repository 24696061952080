<ion-content no-bounce data-testid="user-form">
  <div class="login-background"></div>
  <ion-grid no-padding>
    <ion-row justify-content-center no-padding style="justify-content: center">
      <ion-col size-sm="8" size-lg="6" size-xl="4" align-self-center no-padding>
        <div class="login-header" class="ion-text-center">
          <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
        </div>
        <div class="login-container-wrapper">
          <form novalidate (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" [formGroup]="form">
            <ion-list class="ion-padding-bottom">
              <ion-item>
                <a routerLink="/login" color="medium" translate
                  ><ion-icon name="arrow-back-outline"></ion-icon> Back to login</a
                >
              </ion-item>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>Name</ion-label>
                <ion-input data-testid="name-input" type="text" formControlName="name" autocomplete="name"></ion-input>
              </ion-item>
              <ion-text
                data-testid="name-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('name').hasError('required')"
              >
                <span padding translate>Please enter your full name</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>Email</ion-label>
                <ion-input
                  data-testid="email-input"
                  type="email"
                  formControlName="email"
                  autocomplete="email"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="email-valid-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('email').hasError('email')"
              >
                <span padding translate>Please enter a valid email address</span>
              </ion-text>
              <ion-text
                data-testid="email-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('email').hasError('required')"
              >
                <span padding translate>Please enter your email address</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label color="medium" position="floating" translate>Password</ion-label>
                <ion-input
                  data-testid="password-input"
                  type="password"
                  formControlName="password"
                  autocomplete="new-password"
                ></ion-input>
              </ion-item>
              <ion-text
                data-testid="password-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('password').hasError('required')"
              >
                <span padding translate>Please enter a password</span>
              </ion-text>
              <ion-text
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('password').hasError('minlength')"
              >
                <span padding translate>Password must be longer than 8 characters</span>
              </ion-text>
              <ion-text
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('password').hasError('pattern')"
              >
                <span padding translate>Please must contain a number and a special character</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-checkbox labelPlacement="end" data-testid="terms-input" formControlName="termsPrivacy"
                  >Do you agree to the Terms and Privacy Policy?</ion-checkbox
                >
              </ion-item>
              <ion-text>
                <a (click)="openPage(['https://rssl.me/peProTerms', 'https://rssl.me/outdoorProTerms'])">View Terms</a>
                |
                <a (click)="openPage(['https://rssl.me/peProPrivacy', 'https://rssl.me/outdoorProPrivacy'])"
                  >View Privacy Policy</a
                >
              </ion-text>
              <ion-text
                data-testid="terms-required-error"
                color="danger"
                class="error-message"
                *ngIf="submitted && form.get('termsPrivacy').hasError('required')"
              >
                <span padding translate>To create an account, the Terms and Privacy Policy must be accepted.</span>
              </ion-text>
            </ion-list>
            <ion-button
              data-testid="next-button-step1"
              type="submit"
              color="primary"
              expand="block"
              class="ion-margin-top"
              translate
            >
              Next
            </ion-button>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
