import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ShellComponent } from './pe-pro-shell.component';

@NgModule({
  imports: [CommonModule, TranslateModule, IonicModule, RouterModule],
  declarations: [ShellComponent],
  providers: [AngularFireAuthGuard],
})
export class ShellModule {}
