import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  CollectionReference,
  DocumentReference,
  Query,
} from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@lib/environments/environment';
import { Observable } from 'rxjs';
import { KeyStage, ListBlock } from '../models/content.model';
import { RoutingService } from './routing.service';
import { AdminSettingsService } from './admin-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  private collection = environment.appType === 'pe' ? 'peProCollections' : 'outdoorProCollections';
  constructor(
    private afs: AngularFirestore,
    private routingService: RoutingService,
    private adminSettingsService: AdminSettingsService,
  ) {}

  getKeystagesQuery(collectionId: string): AngularFirestoreCollection<ListBlock> {
    return this.afs.collection<ListBlock>(`${this.collection}/${collectionId}/keystages`, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.where('archived', '==', false);
      query = query.orderBy('order');
      return query;
    });
  }
  getKeystages(collectionId: string): Observable<ListBlock[]> {
    return this.getKeystagesQuery(collectionId).valueChanges({ idField: 'id' });
  }

  async getListBlocksQuery(
    listBlockCollection: string,
    route: ActivatedRouteSnapshot,
    keyStageId?: string,
    alphabetical: boolean = false,
  ): Promise<AngularFirestoreCollection<ListBlock>> {
    const keyStageDocRef = keyStageId
      ? this.afs.doc<ListBlock>(
          `${this.collection}/${await this.adminSettingsService.getCollectionId(route)}/keystages/${keyStageId}`,
        ).ref
      : null;
    return this.afs.collection<ListBlock>(listBlockCollection, (ref) => {
      let query: CollectionReference | Query = ref;
      query = query.where('archived', '==', false);
      if (keyStageId) {
        query = query.where('keyStages', 'array-contains', keyStageDocRef);
      }
      query = alphabetical ? query.orderBy('title') : query.orderBy('order');
      return query;
    });
  }

  async getListBlocks(
    listBlockCollection: string,
    route: ActivatedRouteSnapshot,
    keyStageId?: string,
  ): Promise<Observable<ListBlock[]>> {
    return (await this.getListBlocksQuery(listBlockCollection, route, keyStageId)).valueChanges({ idField: 'id' });
  }

  public async addListBlock(listBlock: ListBlock, route: ActivatedRouteSnapshot, isKeyStage?: boolean): Promise<any> {
    const { collectionRef } = await this.routingService.getLessonsDatabasePath(route, isKeyStage);

    const uid = this.afs.createId();
    await this.afs.doc(`${collectionRef}/${uid}`).set(listBlock);
    return uid;
  }

  public async updateListBlock(
    id: string,
    listBlock: ListBlock,
    route: ActivatedRouteSnapshot,
    isKeyStage?: boolean,
  ): Promise<any> {
    const { collectionRef } = await this.routingService.getLessonsDatabasePath(route, isKeyStage);

    const listBlockDoc = this.afs.doc<ListBlock>(`${collectionRef}/${id}`);

    return listBlockDoc.update(listBlock);
  }

  async getKeystageRef(keyStageId: string, route: ActivatedRouteSnapshot): Promise<DocumentReference<KeyStage>> {
    const { collectionRef } = await this.routingService.getLessonsDatabasePath(route, true);
    return this.afs.doc<KeyStage>(`${collectionRef}/${keyStageId}`).ref;
  }

  getListDocFromRef(listRef: DocumentReference<KeyStage>): AngularFirestoreDocument<KeyStage> {
    return this.afs.doc<KeyStage>(listRef);
  }

  async getAllLessonsByKeystage(
    keystageRef: DocumentReference<KeyStage>,
    route: ActivatedRouteSnapshot,
  ): Promise<AngularFirestoreCollection<ListBlock[]>> {
    return this.afs.collection<ListBlock[]>(
      `${this.collection}/${await this.adminSettingsService.getCollectionId(route)}/lessons`,
      (ref) => {
        let query: CollectionReference | Query = ref;
        query = query.where('archived', '==', false);
        query = query.where('keyStages', 'array-contains', keystageRef);
        query = query.orderBy('order');
        return query;
      },
    );
  }

  async getLessonRef(lessonId: string, route: ActivatedRouteSnapshot): Promise<DocumentReference<ListBlock>> {
    return this.afs.doc<ListBlock>(
      `${this.collection}/${await this.adminSettingsService.getCollectionId(route)}/lessons/${lessonId}`,
    ).ref;
  }
}
