import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { CreateLicenceComponent } from './create-account/create-licence/create-licence.component';
import { CreateUserComponent } from './create-account/create-user/create-user.component';
import { LoginComponent } from './login.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'create-account',
    children: [
      {
        path: '',
        redirectTo: '/create-account/create-user',
        pathMatch: 'full',
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: { title: 'Start a free trial' },
      },
      {
        path: 'create-licence',
        component: CreateLicenceComponent,
        data: { title: 'Start a free trial' },
      },
    ],
  },
  {
    path: 'auth',
    component: AuthComponent,
    data: { title: 'Auth' },
  },
  {
    path: 'verify-email/:id',
    component: VerifyEmailComponent,
    data: { title: 'Verify Email' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoginRoutingModule {}
