import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { ArraySortPipe } from './pipes/alphabetical.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SessionSearchPipe } from './pipes/sessionsearch.pipe';
import { ArchivedPipe } from './pipes/archived.pipe';
import { KeystagePipe } from './pipes/keystage.pipe';
import { TermsResolver } from './resolvers/terms.resolver';
import { IonicModule } from '@ionic/angular';
import { ListBlockComponent } from './components/list-block/list-block.component';
import { HeaderComponent } from './components/header/header.component';
import { ListComponent } from './components/list/list.component';
import { CommonModule } from '@angular/common';
import { AddEditListBlockComponent } from './components/add-edit-list-block/add-edit-list-block.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LicenceUsersComponent } from '@lib/settings/licence/licence-users/licence-users.component';
import { AddEditTagsComponent } from './components/add-edit-tags/add-edit-tags.component';
import { SaniPipe } from './pipes/sani.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { IconBlockComponent } from './components/icon-block/icon-block.component';
import { ListResolver } from './resolvers/list.resolver';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  imports: [IonicModule, RouterModule, CommonModule, FormsModule, AngularEditorModule, ReactiveFormsModule],
  declarations: [
    LoaderComponent,
    ArraySortPipe,
    SafePipe,
    SearchPipe,
    SessionSearchPipe,
    ArchivedPipe,
    KeystagePipe,
    SaniPipe,
    GenderPipe,
    HeaderComponent,
    ListComponent,
    ListBlockComponent,
    AddEditListBlockComponent,
    LicenceUsersComponent,
    AddEditTagsComponent,
    VideoPlayerComponent,
    IconBlockComponent,
  ],
  providers: [TermsResolver, ListResolver],
  exports: [
    LoaderComponent,
    ArraySortPipe,
    SafePipe,
    SearchPipe,
    SessionSearchPipe,
    ArchivedPipe,
    KeystagePipe,
    SaniPipe,
    GenderPipe,
    HeaderComponent,
    ListComponent,
    ListBlockComponent,
    LicenceUsersComponent,
    AddEditTagsComponent,
    VideoPlayerComponent,
    IconBlockComponent,
  ],
})
export class SharedModule {}
