<div class="login-container continue">
  <p *ngIf="credentialsService.userData.name != null && credentialsService.userData.licence != null">
    Logged in as: {{ credentialsService.userData.name }}
  </p>
  <div *ngIf="credentialsService.userData.licence != null">
    <ion-button button class="width-100" routerLink="/tabs/home/">Continue</ion-button>
    <p>Not you? <a class="logout-link" (click)="logout($event)" href="/login">Logout</a></p>
  </div>
  <div *ngIf="credentialsService.userData.licence == null">
    <p>
      You do not have a licence. Please
      <a class="logout-link" (click)="logout($event)" href="/login">logout</a> and try again once your licence has been
      activated.
    </p>
  </div>
</div>
