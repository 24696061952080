<app-header [showBack]="false">
  <ion-button
    *ngIf="credentialsService.userRole?.permissions.editContent"
    button
    color="light"
    class="ion-margin-end"
    slot="end"
    routerLink="/tabs/home/edit-homepage"
    >Edit</ion-button
  >
</app-header>
<ion-content data-testid="home-screen">
  <div class="homepage-wrapper">
    <div class="ion-margin-bottom">
      <div>
        <span class="pre-title">{{ (home | async)?.preTitle }}</span>
        <h1>Hey {{ credentialsService.userData?.name }}!</h1>
        <p *ngIf="this.credentialsService.userLicence?.trial">
          Your trial finishes on {{ credentialsService.userLicence?.expiresAt.toDate() | date: 'dd/MM/yyyy' }}
        </p>
      </div>

      <ion-text color="primary" class="main-content ion-text-left" *ngIf="(home | async)?.mainContent">
        <div [innerHTML]="(home | async)?.mainContent"></div>
      </ion-text>

      <div class="sports-directory-banner">
        <div class="sports-directory-banner__content">
          <div class="sports-directory-banner__logo">
            <img class="home-logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
          </div>
          <div>
            <h2>{{ (home | async)?.sportsTitle }}</h2>
            <p>{{ (home | async)?.sportsSubtitle }}</p>
          </div>
        </div>

        <div class="sports-directory-banner__image">
          <svg class="desktop first" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.82 210">
            <defs>
              <clipPath id="prefix__a"><path fill="none" stroke-width="0" d="M0 0h58.82v210H0z" /></clipPath>
            </defs>
            <g clip-path="url(#prefix__a)">
              <path
                d="M227.35 310.61H-62.74v-340.7h290.09v340.7zM5.26 134.16c0 81.26 38.28 147.14 85.51 147.14s85.51-65.88 85.51-147.14S137.99-12.98 90.77-12.98 5.26 52.9 5.26 134.16z"
                fill="#4279bd"
              />
            </g>
          </svg>
          <svg
            class="mobile first"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 210 28.06"
          >
            <defs>
              <clipPath id="clippath1">
                <path
                  d="m402.48-4.21c.36-.25-116.76,25.25-144.69,25.49-21.09.19-49.32-9.96-61.89-14.95-6.3-2.5-13.18-4.27-20.36-5.08-14.36-1.63-38.13-2.05-62.77,7.29-36.89,13.97-38.88,17.25-101.03,19.37C-60.21,30.35-137.96,3.39-137.96,3.39l73.45-55.15,281.86-6.62,71.99,6.86L402.48-4.21Z"
                  style="fill: none; stroke-width: 0px"
                />
              </clipPath>
            </defs>
            <g style="clip-path: url(#clippath1)">
              <rect
                width="210"
                height="28.06"
                transform="translate(210 28.06) rotate(180)"
                style="fill: #4279bd; stroke-width: 0px"
              />
            </g>
          </svg>
          <img src="{{ (home | async)?.sportsImage }}" alt="Sports Directory Image" />
          <svg
            class="desktop last"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 84.71 210"
          >
            <defs>
              <clipPath id="clippath2">
                <rect width="84.71" height="210" style="fill: none; stroke-width: 0px" />
              </clipPath>
            </defs>
            <g style="clip-path: url(#clippath2)">
              <g>
                <path
                  d="m22.07,156.35l63.37,55.33c29.31,25.59,65.81-6.77,65.7-58.25l-.24-111.3c-.08-37.04-19.07-64-40.6-66.56-8.39-1-17.16,1.71-25.35,8.97L66.11,1.25l-30.88,27.37-13.42,11.9c-29.2,25.89-29.06,90.24.25,115.83Z"
                  style="fill: #c2d3e9; stroke-width: 0px"
                />
                <path
                  d="m69.71-27.62S48.23,21.98,31.55,31.8s57.92,8.69,57.92,8.69l-19.76-68.11Z"
                  style="fill: #c2d3e9; stroke-width: 0px"
                />
              </g>
            </g>
          </svg>
          <svg
            class="mobile last"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 210 28.26"
          >
            <defs>
              <clipPath id="clippath3">
                <path
                  d="m-83.79,32.27c-.25.25,79.9-25.25,99.02-25.49,14.43-.19,33.75,9.96,42.35,14.95,4.31,2.5,9.02,4.27,13.94,5.08,9.83,1.63,26.09,2.05,42.96-7.29C139.72,5.55,141.08,2.27,183.61.16c49.25-2.45,102.45,24.51,102.45,24.51l-50.26,55.15-192.89,6.62-49.26-6.86-77.43-47.3Z"
                  style="fill: none; stroke-width: 0px"
                />
              </clipPath>
            </defs>
            <g style="clip-path: url(#clippath3)">
              <rect y="-152.42" width="210" height="180.68" style="fill: #c2d3e9; stroke-width: 0px" />
            </g>
          </svg>
        </div>

        <div class="sports-directory-banner__sponsor">
          <ion-button
            class="ion-margin-top"
            color="primary"
            size="default"
            (click)="
              openPage(
                isWeb
                  ? 'https://www.sportsdirectoryuk.co.uk/?utm_source=PE+Pro+Website&utm_medium=Link&utm_campaign=PE+Pro'
                  : 'https://www.sportsdirectoryuk.co.uk/?utm_source=PE+Pro+App&utm_medium=Link&utm_campaign=PE+Pro'
              )
            "
            >Buy Your Equipment
            <ion-icon name="chevron-forward-outline" class="icon"></ion-icon>
          </ion-button>
          <p>Sponsored by</p>
          <a
            (click)="
              openPage(
                isWeb
                  ? 'https://www.sportsdirectoryuk.co.uk/?utm_source=PE+Pro+Website&utm_medium=Link&utm_campaign=PE+Pro'
                  : 'https://www.sportsdirectoryuk.co.uk/?utm_source=PE+Pro+App&utm_medium=Link&utm_campaign=PE+Pro'
              )
            "
          >
            <img class="sports-directory-logo" src="assets/sports-directory.png" alt="Sports Directory Logo" />
          </a>
        </div>
      </div>

      <h2 class="quick-links-title">Quick Links</h2>
      <div class="quick-links">
        <app-icon-block title="Curriculum" routerLink="/tabs/curriculum">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="clipboard"
            class="svg-inline--fa fa-clipboard fa-w-12"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="auto"
            height="100%"
          >
            <path
              fill="currentColor"
              d="M384 112v352c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h80c0-35.29 28.71-64 64-64s64 28.71 64 64h80c26.51 0 48 21.49 48 48zM192 40c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24m96 114v-20a6 6 0 0 0-6-6H102a6 6 0 0 0-6 6v20a6 6 0 0 0 6 6h180a6 6 0 0 0 6-6z"
            ></path>
          </svg>
        </app-icon-block>
        <app-icon-block title="Lessons" routerLink="/tabs/lessons">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="whistle"
            class="svg-inline--fa fa-whistle fa-w-20"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            width="auto"
            height="100%"
          >
            <path
              fill="currentColor"
              d="M232 256c0 13.2-10.8 24-24 24s-24-10.8-24-24 10.8-24 24-24 24 10.8 24 24zm252-49.7l150 120c6.7 5.4 8 15.2 2.8 22.1l-78.2 104.3c-4.8 6.5-13.7 8.3-20.7 4.3l-150.4-86C312.1 484.4 150.8 492.8 61 403c-65.5-65.5-78.1-163.8-38-242-14.4-11.8-23-29.7-23-49 0-35.3 28.7-64 64-64 19.2 0 36.9 8.9 49 23.5 65.3-33.6 144.4-30.8 207 9.3l86.6 63.6c5.3 4.2 7.3 11.3 5.1 17.7l-10.2 29.5c-1.1 3.2-.1 6.7 2.6 8.9l25.2 20.2c2.6 2.1 6.2 2.3 9.1.6l27.4-16.2c5.8-3.4 13-2.9 18.2 1.2zM85.5 88.3C79.7 83 72 80 64 80c-17.7 0-32 14.3-32 32 0 8.1 3.3 15.4 8.4 21.2 15.2-20.6 27.3-31.9 45.1-44.9zM280 256c0-39.7-32.3-72-72-72s-72 32.3-72 72 32.3 72 72 72 72-32.3 72-72z"
            ></path>
          </svg>
        </app-icon-block>
        <app-icon-block title="Assessments" [routerLink]="'/tabs/assessments/' + credentialsService.userLicence.id">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="check-circle"
            class="svg-inline--fa fa-check-circle fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="auto"
            height="100%"
          >
            <path
              fill="currentColor"
              d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
            ></path>
          </svg>
        </app-icon-block>
        <app-icon-block title="Documents" routerLink="/tabs/docs">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="file-alt"
            class="svg-inline--fa fa-file-alt fa-w-12"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="auto"
            height="100%"
          >
            <path
              fill="currentColor"
              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
            ></path>
          </svg>
        </app-icon-block>
      </div>

      <div class="ion-margin-bottom sports">
        <h2>Follow Us</h2>
        <div class="social-media">
          <a (click)="openPage('https://www.facebook.com/peproapp')">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a (click)="openPage('https://twitter.com/PE_ProApp')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1668.56 1221.19">
              <path
                d="M336.33 142.251l386.39 516.64-388.83 420.05h87.51l340.42-367.76 275.05 367.76h297.8l-408.13-545.7 361.92-390.99h-87.51l-313.51 338.7-253.31-338.7h-297.8zm128.69 64.46h136.81l604.13 807.76h-136.81l-604.13-807.76z"
              />
            </svg>
          </a>
          <a (click)="openPage('https://www.instagram.com/pe_proapp/')">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a (click)="openPage('https://www.linkedin.com/company/peproapp/')">
            <ion-icon name="logo-linkedin"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</ion-content>
