import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { User } from '@lib/shared/models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private afs: AngularFirestore) {}

  public async createUser(uid: string, userDetails: User): Promise<any> {
    return await this.afs.doc(`users/${uid}`).set(userDetails);
  }

  getUserFromRef(reference: DocumentReference): Observable<User> {
    const userDocument = this.afs.doc<User>(reference);
    return userDocument.valueChanges({ idField: 'id' });
  }

  public async updateUser(userId: string, update: any): Promise<void> {
    return await this.afs.doc<User>(`users/${userId}`).update(update);
  }
}
