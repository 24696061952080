<form (ngSubmit)="sendResetPassword()" [formGroup]="resetForm">
  <ion-list>
    <ion-item>
      <a (click)="hideResetPassword()" color="medium" translate
        ><ion-icon name="arrow-back-outline"></ion-icon> Back to login</a
      >
    </ion-item>
    <ion-item>
      <p class="ion-padding">
        If it's your first time logging in or you've forgotten your password, enter your email address here. You'll then
        receive a link to set a new password to be able to access the app.
      </p>
    </ion-item>
    <ion-item lines="inset">
      <ion-label color="medium" position="floating" translate>Email address</ion-label>
      <ion-input type="text" formControlName="username" autocomplete="username"></ion-input>
      <ion-note slot="error" *ngIf="resetForm.get('username').dirty && resetForm.get('username')?.hasError('required')">
        Email address is required
      </ion-note>
      <ion-note slot="error" *ngIf="resetForm.get('username').dirty && resetForm.get('username')?.hasError('email')">
        Email address is not a valid email address
      </ion-note>
    </ion-item>
  </ion-list>
  <div class="login-button-container" padding-horizontal>
    <ion-button type="submit" [disabled]="resetForm.invalid" color="primary" expand="block" translate>
      Send me an email
    </ion-button>
  </div>
</form>
