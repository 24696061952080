import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private platform: Platform) {}

  get isWeb(): boolean {
    return !this.platform.is('cordova');
  }
}
