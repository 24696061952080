import { Component, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CredentialsService } from '@lib/core/authentication/credentials.service';
import { ToastService } from '@lib/shared/services/toast.service';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-user',
  templateUrl: './add-user.component.html',
})
@Sentry.TraceClassDecorator()
export class AddUserComponent implements OnDestroy {
  public query: string;
  public addUser: UntypedFormGroup;
  public submitted: boolean;
  public submitting: boolean = false;
  private licenceId: string;
  private userSub: Subscription = new Subscription();
  private tokenSub: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    public credentialsService: CredentialsService,
    public alertController: AlertController,
    private modalController: ModalController,
    private fns: AngularFireFunctions,
    private toastService: ToastService
  ) {}

  @Sentry.TraceMethodDecorator()
  ngOnInit() {
    this.addUser = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });

    this.submitted = false;
  }

  @Sentry.TraceMethodDecorator()
  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.tokenSub.unsubscribe();
  }

  public async onSubmit() {
    if (this.addUser.invalid) {
      this.errorMessage('Name and email are required and the email has to be a valid email address.');
      this.submitted = true;
      this.submitting = false;
    } else {
      this.submitting = true;
      this.tokenSub = this.credentialsService.token.subscribe({
        next: (token: string) => {
          this.tokenSub.unsubscribe();
          const data = { ...this.addUser.value, token, licenceId: this.licenceId };
          const addUser = this.fns.httpsCallable('addUserV2');
          const user = addUser(data);

          this.userSub = user.subscribe({
            next: async () => {
              this.userSub.unsubscribe();
              this.modalController.dismiss({
                data: user,
              });
              this.submitting = false;
            },
            error: async (error) => {
              switch (error.code) {
                case 'aborted':
                  await this.errorMessage('This email address already has an account.');
                  break;
                case 'forbidden':
                  await this.errorMessage('You do not have permission to add another user.');
                  break;
                default:
                  await this.errorMessage('There has been an error. Please try again.');
                  break;
              }
              this.submitting = false;
              this.dismissModal();
              this.userSub.unsubscribe();
            },
          });
        },
        error: (error) => {
          console.error(error);
          this.toastService.showDissmissableErrorToast('Could not get your auth token. Please try again.');
        },
      });
    }
  }

  async errorMessage(message: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message,
      buttons: [
        {
          text: 'Close',
        },
      ],
    });

    await alert.present();
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      data: null,
    });
  }
}
