<ion-content no-bounce>
  <div class="login-background"></div>
  <ion-grid no-padding>
    <ion-row justify-content-center no-padding style="justify-content: center">
      <ion-col size-sm="8" size-lg="6" size-xl="4" align-self-center no-padding>
        <div class="login-header" class="ion-text-center">
          <img class="logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
        </div>
        <div class="login-container-wrapper">
          <div class="login-spinner-wrapper" *ngIf="isLoading">
            <ion-spinner class="primary-spinner"></ion-spinner>
          </div>
          <app-logged-in *ngIf="credentialsService.isAuthenticated && !isLoading"></app-logged-in>
          <div *ngIf="!credentialsService.isAuthenticated && !isLoading" class="login-container">
            <app-login-form *ngIf="!resetPassword"></app-login-form>
            <app-reset-password-email *ngIf="resetPassword"></app-reset-password-email>
          </div>
        </div>
        <p class="ion-padding version-number">Current version: {{ version }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
