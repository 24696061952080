import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '@lib/environments/environment';
import { Licence, User } from '@lib/shared/models/user.model';
import { Timestamp } from 'firebase/firestore';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpiredLicenceGuard {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
  ) {}

  async canActivate(): Promise<boolean> {
    const auth = await firstValueFrom(this.afAuth.authState);
    const userDoc = this.afs.doc<User>(`users/${auth.uid}`);
    const user = await firstValueFrom(userDoc.valueChanges({ idField: 'id' }));
    const licence = await firstValueFrom(this.afs.doc<Licence>(user.licence).valueChanges());
    const notExpiredName = environment.appType === 'pe' ? 'notExpired' : 'outdoorProNotExpired';

    if (!licence) {
      this.router.navigate(['/no-licence']);
      return false;
    }

    const newUserDoc = {
      notExpired: !!(
        this.dateIsBefore(licence?.expiresAt as Timestamp) && this.dateIsAfter(licence.validFrom as Timestamp)
      ),
      outdoorProNotExpired: !!(
        this.dateIsBefore(licence?.outdoorProExpiresAt as Timestamp) &&
        this.dateIsAfter(licence?.outdoorProValidFrom as Timestamp)
      ),
    };

    userDoc.update(newUserDoc);

    if (!newUserDoc[notExpiredName]) {
      this.router.navigate(['/licence-expired']);
      return false;
    }

    return true;
  }

  private dateIsBefore(date: Timestamp): boolean {
    return date && moment().isSameOrBefore(date.toDate());
  }

  private dateIsAfter(date: Timestamp): boolean {
    return date && moment().isSameOrAfter(date.toDate());
  }
}
