import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { ContentService } from '../services/content.service';
import * as base64 from 'base-64';

@Injectable()
export class ContentResolver {
  constructor(
    private router: Router,
    private contentService: ContentService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    try {
      const blockTitle = route.queryParams?.title ? base64.decode(route.queryParams.title) : '';
      const lessonPlan = route.queryParams?.lessonPlan ? base64.decode(route.queryParams.lessonPlan) : '';
      const contentId = route.paramMap.get('contentId');
      const keyStageId = route.paramMap.get('keyStage');
      const rows = await firstValueFrom(
        (await this.contentService.getRowsQuery(contentId, route, keyStageId))
          .get()
          .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
      );

      const content = {
        title: blockTitle,
        lessonPlan,
        rows: await Promise.all(
          rows.map(async (row) => {
            const blocks = await firstValueFrom(
              (await this.contentService.getBlocksQuery(contentId, row.id, route))
                .get()
                .pipe(map((snapshot) => snapshot.docs.map((a) => ({ id: a.id, ...a.data() })))),
            );
            return { ...row, blocks };
          }),
        ),
      };

      return content;
    } catch (error) {
      console.error(error);
      this.router.navigate(['/problem-with-the-service']);
      return Promise.reject();
    }
  }
}
