import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@lib/environments/environment';
import { AdminSettings } from '../models/admin-settings.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminSettingsService {
  public settings: AngularFirestoreDocument<AdminSettings>;
  private collection = environment.appType === 'pe' ? 'pePro' : 'outdoorPro';

  constructor(private afs: AngularFirestore) {
    this.settings = this.afs.doc<AdminSettings>(`${this.collection}/settings`);
  }

  getSettings(): AngularFirestoreDocument<AdminSettings> {
    return this.settings;
  }

  async updateSettings(settings: Partial<AdminSettings>) {
    return this.settings.update(settings);
  }

  async getCollectionId(route: ActivatedRouteSnapshot): Promise<string> {
    const routeCollectionId = route.paramMap.get('collectionId')
      ? route.paramMap.get('collectionId')
      : route.parent.paramMap.get('collectionId');
    const defaultCollection = await firstValueFrom(
      this.getSettings()
        .get()
        .pipe(map((a) => ({ id: a.id, ...a.data() }))),
    );
    const collectionId = routeCollectionId ? routeCollectionId : defaultCollection.liveCollection.id;
    return collectionId;
  }
}
